
export default {
  name: 'GenLocationNames',
  data() {
    return {
      dayStartStr: this.$store.getters.virtualDay.format('YYYY-MM-DD'),
      dayEndStr: this.$store.getters.virtualDay.add(7, 'days').format('YYYY-MM-DD'),
    };
  },
  computed: {
    dayStart() {
      return this.$dayjs(this.dayStartStr, 'YYYY-MM-DD', true);
    },
    isValidDayStart() {
      return this.dayStart.isValid();
    },
    dayEnd() {
      return this.$dayjs(this.dayEndStr, 'YYYY-MM-DD', true);
    },
    isValidDayEnd() {
      return this.dayEnd.isValid();
    },
    isValidDayRange() {
      return this.isValidDayStart && this.isValidDayEnd;
    },
    daysDiff() {
      let days = 0;

      if (this.isValidDayRange) {
        days = this.dayEnd.diff(this.dayStart, 'days');
      }

      return days;
    },
    locationNames() {
      const names = [];

      if (this.isValidDayRange) {
        for (let i = 0; i < Math.min(1000, this.daysDiff); i++) {
          const d = this.dayStart.add(i, 'days');
          const name = this.$store.getters['gen/getLocationNameForDay'](d);
          const isRepeat = names.find(n => n.name === name);

          // These must match the logic in store/gen and store/index
          const seed = this.$store.getters.getSeedForDay(d);

          names.push({
            name,
            dateStr: d.format('YYYY-MM-DD'),
            isRepeat,
            seed,
            sunColor: this.$store.getters['gen/getSunColorForDay'](d).map(v => v * 150).join(','),
          });
        }
      }

      return names;
    },
    countLocationNamesRepeat() {
      return this.locationNames.filter(n => n.isRepeat).length;
    },
  },
};
